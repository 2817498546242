// let fecha = new Date();
const state = {
  // FLAG
  progress: 0,
  loading: false,
  dialogVersion: false,
  overlay: false,
  flag: true,
  dialogLlamadas: false,
  dialog: true,
  stepCarga: false,
  stepServicio: false,
  stepCosto: false,
  stepNota: false,
  stepInstructivo: false,
  cudFlag: null,
  registroCompleto: false,
  datosServiciosFlag: false,
  nuevoUsuarioFlag: false,
  usuarioSeleccionadoFlag: false,
  calculadorFlag: false,
  fclflag: false,
  step1flag: true,
  step2flag: false,
  step3flag: false,
  registrarFlag: false,
  cotizarFlag: false,
  editarFlag: false,
  precotizacionFlag: false,
  impuestoFlag: false,
  botonRegistrarFlag: false,
  cargarServicios: false,
  condition: false,
  archivosFlag: true,
  dataMenu: false,
  registroLlamadaFlag: false,
  actualizarListaArchivos: false,
  modalContactsEdit: false,
  modalContacts: false,
  // objetos

  //
  branch: [],
  provincias: [],
  distritos: [],
  instructivo: [],
  status: [],
  entities: [],
  modality: [],
  shipment: [],
  incoterm: [],
  portBegin: [],
  portEnd: [],
  services: [],
  costos: [],
  roles: [],
  containersList: [],
  listadoCliente: [],
  begEndList: [],
  opcionMenu: [],
  // datosPrincipales: [],
  proveedor: [],
  multiplicador: [],
  llamadas: [],
  datosVer: [],
  datosReporte: [],
  totalOpcion: { flete: 0, gasto: 0, aduana: 0, almacen: 0 },
  totalVenta: { flete: 0, gasto: 0, aduana: 0, almacen: 0 },
  totalImpuesto: null,
  archivos: [],
  listFiile: [],
  itemsEntities: [],
  itemsDocumentsPais: [],
  itemsPais: [],
  itemsDataRoleList: [],
  modalPhones: false,
  marketingList: [],
  quoteOpen: [],
  reportsRangeDays: [],
  listadoQuoteCasillero: [],
  listadoCasillero: [],
  instructivoCasillero: [],
  quoteSales: [],
  casillero: {
    volumen: 0,
    peso: 0,
    containers: 0,
    nroBultos: 0,
    ganancia: 0,
    expediente: "",
    fechaSalida: null,
    listQuote: [],
  },
  modalPhonesEdit: false,
  modalCuentas: false,
  // JSON
  datosPrincipales: {
    id: null,
    idstatus: null,
    identities: 4,
    idPricing: 4,
    amount: null,
    idmodality: null,
    idshipment: null,
    idincoterm: null,
    idPortBegin: null,
    idPortEnd: null,
    idCliente: null,
    name: null,
    phone: null,
    bultos: null,
    metroscc: null,
    kg: null,
    idprovincia: 128,
    iddistrito: 1279,
    descripcioncarga: "",
    proveedor: null,
    telefonoproveedor: null,
    direccionproveedor: null,
    code: null,
    idmarketing: null,
    fecha_fin: null,
    fecha_inicio: null,
    tiempo_transito: 0,
    notesFixed: [
      { description: "SALIDAS SEMANALES", state: 1 },
      {
        description: "LAS TARIFAS NO APLICAN PARA MERCANCIA CON SOBREPESO",
        state: 1,
      },
      {
        description: "LAS TARIFAS NO APLICAN PARA MERCANCIA PELIGROSA",
        state: 1,
      },
      {
        description:
          "LOS DOCUMENTOS DEL EMBARQUE SON RESPONSABILIDAD DEL PROVEEDOR Y EL CONSIGNATARIO",
        state: 1,
      },
      {
        description: "EL EMBALAJE DE LA CARGA DEBE SER  ACTO PARA TRANSPORTE",
        state: 1,
      },
      {
        description:
          "SI EL CANAL CONSIGANDO POR LA ADUANA ES ROJO EL COSTO DEL ALMACEN AUMENTARA EN 150-200 USD",
        state: 1,
      },
      {
        description:
          "TODOS LOS SERVICIOS QUE SE REALICEN DENTRO DEL TERRITORIO PERUANO TIENEN UN IGV DEL 18%",
        state: 1,
      },
      {
        description: "COSTOS ADICIONALES POR INSPECCIONES SUGERIDAS DE ADUANA",
        state: 1,
      },
    ],
    containers: [],
    paisCalc: [],
    puertoCalc: [],
    monedaCalc: [],
    navieraCalc: [],
    contendedorCalc: [],
    impuestos: [
      { type: 1, name: "FOB", percentage: 0, valor: null, orden: 1 },
      { type: 2, name: "FLETE", percentage: 0, valor: null, orden: 2 },
      { type: 3, name: "SEGURO", percentage: 1.5, valor: null, orden: 3 },
      { type: 4, name: "CIF", percentage: null, valor: null, orden: 4 },
      { type: 5, name: "AD VALOREN", percentage: 6, valor: null, orden: 5 },
      { type: 6, name: "ISC", percentage: 0.0, valor: null, orden: 6 },
      { type: 7, name: "IPM", percentage: 2.0, valor: null, orden: 7 },
      { type: 8, name: "IGV", percentage: 16.0, valor: null, orden: 8 },
      { type: 9, name: "PERCEPCIONES", percentage: 10, valor: null, orden: 9 },
    ],
  },
  datosPreCotizacion: {
    nombre: "",
    telefono: "",
    idmodality: "",
    idshipment: "",
    idincoterm: "",
    archivos: [],
    idstatus: 3,
  },
  //  TIPO TEXTO
  titulo: null,
  idQuote: "",
  tabCotizar: 0,
  datosPrincipalesClass: "active",
  datosCargaClass: "inactive",
  serviciosClass: "inactive",
  cotizarClass: "inactive",
  datosCarga: null,
  factor: 0,
  totalDeFlete: 0,
  itemsList: [],
  itemsMenu: [],
  dataList: [],
  itemsState: [],
  itemsCity: [],
  itemsTown: [],
  itemsSex: [],
  itemsAgents: [],
  itemsConsigners: [],
  itemsNotify: [],
  itemsNavieras: [],
  itemsColoaders: [],
  quoteList: [],
  quoteCall: [],
  itemsListPhone: [],
  itemsListPhoneEdit: [],
  id_entitie_selected: [],
  itemsListContactEdit: [],
  itemsListContact: [],
  itemsPhone: [
    {
      id: 1,
      name: "Oficina",
    },
    {
      id: 2,
      name: "Celular",
    },
  ],
  itemsDataAccountList: [],
  itemsDataBanksList: [],
  itemsCoinsList: [],
  accountIdTypeAccount: 1,
  accountIdBanks: "",
  accountIdCoins: "",
};

export default state;
