import axios from "axios";
// import Vue from "vue";
import router from "@/router";
// import Swal from "sweetalert2";
// validToken
const state = {};
const mutations = {};
const actions = {
  async validTokenLogin() {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token"))
        ? JSON.parse(localStorage.getItem("token"))
        : "",
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "validToken",
      headers: headers,
    };
    await axios(config).then((response) => {
      let data = response.data;

      if (data.estadoflag == true) {
        if (router.currentRoute.name == "login") {
          router.push("/listado");
        }
      } else {
        if (router.currentRoute.name != "login") {
          router.push({ name: "login" });
        }
      }
    });
  },
  //   async validTokenLogin() {
  //     var headers = {
  //       "auth-token": JSON.parse(localStorage.getItem("token")),
  //       "Content-Type": "application/json",
  //     };

  //     var config = {
  //       method: "post",
  //       url: process.env.VUE_APP_URL_MAIN + "validToken",
  //       headers: headers,
  //     };
  //     await axios(config).then((response) => {
  //       let data = response.data;
  //       if (data.estadoflag == true) {
  //         console.log(router);
  //         // router.push("/listado");
  //       }
  //     });
  //   },
};
export default {
  namespace: true,
  state,
  mutations,
  actions,
};
