import axios from "axios";
import Swal from "sweetalert2";
const state = {
  listRecibidoCotizacion: [],
  listEnviadoCliente: [],
};

const mutations = {
  SET_CARGAR_LIST_RECIBIDO_COTIZACION(state, data) {
    state.listRecibidoCotizacion = data;
  },
  SET_CARGAR_LIST_ENVIADO_CLIENTE(state, data) {
    state.listEnviadoCliente = data;
  },
};
// JSON.parse(localStorage.getItem("branch"))
const actions = {
  async getListRecibidoCotizacion({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      url:
        process.env.VUE_APP_URL_MAIN +
        `cargar_master_detalle_recibido?id_branch=${JSON.parse(
          localStorage.getItem("branch")
        )}`,
      headers: headers,
    };
    axios(config).then((response) => {
      let data = response.data;
      if (data.estado == true) {
        commit("SET_CARGAR_LIST_RECIBIDO_COTIZACION", response.data.data);
      } else {
        Swal.fire({
          icon: "error",
          text: data.mensaje,
        });
        commit("SET_CARGAR_LIST_RECIBIDO_COTIZACION", []);
      }
    });
  },
  async getListEnviadoCliente({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      url:
        process.env.VUE_APP_URL_MAIN +
        `cargar_master_detalle_enviado?id_branch=${JSON.parse(
          localStorage.getItem("branch")
        )}`,
      headers: headers,
    };
    axios(config).then((response) => {
      let data = response.data;
      if (data.estado == true) {
        commit("SET_CARGAR_LIST_ENVIADO_CLIENTE", response.data.data);
      } else {
        Swal.fire({
          icon: "error",
          text: data.mensaje,
        });
        commit("SET_CARGAR_LIST_ENVIADO_CLIENTE", []);
      }
    });
  },
  async actualizarQuoteRecibidoEnviado(_, data) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };

    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_MAIN + `update_quote_recibido_enviado`,
      headers: headers,
      data: data,
    };
    axios(config).then((response) => {
      let data = response.data;

      Swal.fire({
        icon: data.estado == true ? "info" : "error",
        text: data.mensaje,
      });
    });
  },
};
export default {
  namespace: true,
  state,
  mutations,
  actions,
};
